export function getToken() {
    return sessionStorage.getItem("token")
}


export function getUserID() {
    return sessionStorage.getItem("User_ID")
}


export function getStaffName() {
    return sessionStorage.getItem("Staff_Name")
}

export function getUsername() {
    return sessionStorage.getItem("username")
}


export function getMobile() {
    return sessionStorage.getItem("Mobile")
}

export function getOpenid() {
    return sessionStorage.getItem("Openid")
}
