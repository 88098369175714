import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/Home'),
  },
  {
    path: '/index',
    name: 'Index',
    component: () => import('@/views/index'),
  },
  {
  path: '/Login',
  name: 'Login',
  component: () => import('@/views/login')
  },
    //用户投诉
  {
    path: '/yhts',
    name: 'index',
    component: () => import('@/views/yhts'),
  },
  {
    path: '/yhtsAdd',
    name: 'yhtsAdd',
    component: () => import('@/views/yhts/components/yhtsAdd'),
  },
  {
    path: '/yhtsList',
    name: 'yhtsList',
    component: () => import('@/views/yhts/components/yhtsList'),
  },
  {
    path: '/yhtsInfo',
    name: 'yhtsInfo',
    component: () => import('@/views/yhts/components/yhtsInfo'),
  },
  //商家投诉
  {
    path: '/sjts',
    name: 'index',
    component: () => import('@/views/sjts'),
  },
    //商家店铺列表
  {
    path: '/shopList',
    name: 'shopList',
    component: () => import('@/views/sjts/components/shopList'),
  },
  {
    path: '/shopInfo',
    name: 'shopInfo',
    component: () => import('@/views/sjts/components/shopInfo'),
  },
    //门前三包
  {
    path: '/mqsbQd',
    name: 'mqsbQd',
    component: () => import('@/views/mqsb/components/mqsbQd'),
  },
  {
    path: '/mqsbSs',
    name: 'mqsbSs',
    component: () => import('@/views/mqsb/components/mqsbSs'),
  },
  {
    path: '/mqsbTs',
    name: 'mqsbTs',
    component: () => import('@/views/mqsb/components/mqsbTs'),
  },
  //这个路由放在最后，新增路由写在上面，此处仅作测试版本调试使用
  // {
  // path: '/',
  // name: 'Login',
  // component: () => import('@/views/login')
  // },
]

const router = new VueRouter({
  routes,
})

router.beforeEach((to, from, next) => {

  if(to.path === '/') return next();
  const openid = window.sessionStorage.getItem('Openid');
  if(!openid) return next('/');
  next();

})

export default router

